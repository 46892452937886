import Container_CenterRibbonComponent from '../components/Container/viewer/skinComps/CenterRibbon/CenterRibbon.skin';


const Container_CenterRibbon = {
  component: Container_CenterRibbonComponent
};


export const components = {
  ['Container_CenterRibbon']: Container_CenterRibbon
};

